import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import RecentActorsOutlinedIcon from "@mui/icons-material/RecentActorsOutlined";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
// import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import server from "../../server";
import { NavBarContext } from "../Navbar/Navbar";
import handleProfileImage from "../Util/HandleProfileImage";

const header = {
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  padding: "20px",
  backgroundColor: "#fff",
  borderBottom: "1px solid #EAEAEA",
};

const arrowStyle = {
  margin: "0px 20px 0px 0px",
};

const CategoryTitle = {
  fontWeight: "600",
  fontSize: "20px",
  fontFamily: "Poppins",
  color: "#37474F",
};

const UserName = {
  fontFamily: "poppins",
  fontSize: "20px",
  fontWeight: 400,
  margin: "0px  0px 0px 10px",
};

const UserPhone = {
  fontFamily: "quicksand",
  fontSize: "16px",
  fontWeight: "400",
  margin: "0px  0px 0px 50px",
};

const ProfileSection = {
  display: "flex",
  borderTop: "1px solid #E2E2E2",
  padding: "15px 20px",
  justifyContent: "space-between",
};

const ProfileSectionLast = {
  display: "flex",
  borderBottom: "1px solid #E2E2E2",
  borderTop: "1px solid #E2E2E2",
  padding: "15px 20px",
  justifyContent: "space-between",
};

const ProfileSectionText = {
  fontFamily: "Be Vietnam Pro",
  fontSize: "19px",
  fontWeight: "700",
  margin: "0px 0px 0px 20px",
  color: "#181725",
};

const ProfileSectionDiv = {
  display: "flex",
};

const SectionIcon = { color: "#181725" };

const ButtonCart = {
  backgroundColor: "#F2F3F2",
  color: "#53B175",
  textTransform: "none",
  padding: "10px",
  fontSize: "18px",
  fontWeight: "600",
  width: "100%",
  fontFamily: "quicksand",
  borderRadius: "19px",
  margin: "20px 0px 0px 0px",
  "&.MuiButtonBase-root:hover": {
    background: "#F2F3F2",
  },
  position: "relative",
  border: "3px solid #53B175",
};

const buttonIcon = { position: "absolute", left: "20px" };

const Profile = () => {
  const navigate = useNavigate();
  const navcontext = useContext(NavBarContext);
  const { setClient } = navcontext;
  const [userinfo, setUserInfo] = useState([]);
  const [EditMode, setMode] = useState(false);
  const [name, setname] = useState("");
  const [images, setImages] = useState("");
  const [open1, setOpen1] = useState(false);

  const logoutHandler = async (e) => {
    e.preventDefault();
    handleOpen();
    localStorage.removeItem("user");
    localStorage.removeItem("accessToken");
    setClient(false);
    localStorage.removeItem("cart");
    localStorage.removeItem("cartprice");
    navigate("/");
  };

  const handleOpen = () => setOpen1(true);
  const handleClose = () => setOpen1(false);

  const getName = async () => {
    const result = await axios.get(
      `${server}/users/${localStorage.getItem("user")}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    // console.log(result)
    setUserInfo(result.data);
    setname(result.data.name);
    setImages(result.data.imageUrl);
  };

  const changename = async (e) => {
    e.preventDefault();
    setMode(false);
    // console.log(e.target.value)
    //change in backend
    const result = await axios.put(
      `${server}/user/update/${localStorage.getItem("user")}/${name}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    // console.log(result)
  };

  const handleImageClick = () => {
    // Open the file input when the image is clicked
    document.getElementById("image-input").click();
  };

  const handleImageUpload = async (e) => {
    const uploadedurl = await handleProfileImage({
      images: e.target.files[0],
      setImages: setImages,
    });
    //update image in db
    const updateduser = await axios.put(
      `${server}/user/update/${localStorage.getItem("user")}`,
      {
        imageUrl: uploadedurl,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    setImages(uploadedurl);
  };

  useEffect(() => {
    if (!localStorage.getItem("user")) navigate("/");
    else getName();
  }, []);

  // useEffect(() => {}, [userinfo, EditMode, name, open1]);

  return (
    <Box sx={{ marginBottom: "100px" }}>
      <Box sx={header}>
        <Box sx={arrowStyle}>
          <Link to="/">
            <ArrowBackRoundedIcon />
          </Link>
        </Box>
        <Typography variant="h6" sx={CategoryTitle}>
          My Profile
        </Typography>
      </Box>
      <Box sx={{ padding: "10px 20px" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box>
            <input
              type="file"
              id="image-input"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => handleImageUpload(e)}
            />
            <Box
              onClick={handleImageClick}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <img
                src={images}
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "80%",
                  objectFit: "contain",
                }}
              />
            </Box>
          </Box>
          {EditMode ? (
            <>
              <TextField
                sx={UserName}
                placeholder={userinfo.name}
                onChange={(e) => setname(e.target.value)}
                size="small"
              ></TextField>
              <Button
                onClick={(e) => changename(e)}
                sx={{
                  backgroundColor: "#CD7F32",
                  color: "#fff",
                  textTransform: "none",
                  padding: "10px 10px",
                  fontSize: "10px",
                  fontWeight: "500",
                  width: "10%",
                  "&.MuiButtonBase-root:hover": {
                    background: "#D98B3B",
                  },
                  ml: 1,
                }}
              >
                Save
              </Button>
            </>
          ) : (
            <>
              <Typography sx={UserName}>{name}</Typography>
              <CreateOutlinedIcon
                style={{ color: "#53B175" }}
                onClick={(e) => setMode(!EditMode)}
              />
            </>
          )}
        </Box>

        <Typography sx={UserPhone}>{userinfo.mobile}</Typography>
      </Box>
      <Box sx={{ margin: "20px 0px" }}>
        <Link to="/client/orders" style={{ textDecoration: "none" }}>
          <Box sx={ProfileSection}>
            <Box sx={ProfileSectionDiv}>
              <LocalMallOutlinedIcon sx={SectionIcon} />
              <Typography sx={ProfileSectionText}>Orders</Typography>
            </Box>
            <ArrowForwardIosRoundedIcon />
          </Box>
        </Link>
        <Link to="/wishlist" style={{ textDecoration: "none" }}>
          <Box sx={ProfileSection}>
            <Box sx={ProfileSectionDiv}>
              <RecentActorsOutlinedIcon sx={SectionIcon} />
              <Typography sx={ProfileSectionText}>My Wishlist</Typography>
            </Box>
            <ArrowForwardIosRoundedIcon />
          </Box>
        </Link>
        <Link to="/myaddress" style={{ textDecoration: "none" }}>
          <Box sx={ProfileSection}>
            <Box sx={ProfileSectionDiv}>
              <LocationOnOutlinedIcon sx={SectionIcon} />
              <Typography sx={ProfileSectionText}>My Addresses</Typography>
            </Box>
            <ArrowForwardIosRoundedIcon />
          </Box>
        </Link>

        {/* <Link style={{ textDecoration: "none" }}>
					<Box sx={ProfileSection}>
						<Box sx={ProfileSectionDiv}>
							<HelpOutlineOutlinedIcon sx={SectionIcon} />
							<Typography sx={ProfileSectionText}>
								Help
							</Typography>
						</Box>
						<ArrowForwardIosRoundedIcon />
					</Box>
				</Link> */}
        <Link to="/about" style={{ textDecoration: "none" }}>
          <Box sx={ProfileSectionLast}>
            <Box sx={ProfileSectionDiv}>
              <InfoOutlinedIcon sx={SectionIcon} />
              <Typography sx={ProfileSectionText}>About</Typography>
            </Box>
            <ArrowForwardIosRoundedIcon />
          </Box>
        </Link>
      </Box>
      <div className="px-1">
        <p className="text-lg text-gray-600 ml-3">Coming Soon!</p>
        {/* <Link to="/notification" style={{ textDecoration: "none" }} > */}
        <Box sx={ProfileSection}>
          <Box sx={ProfileSectionDiv}>
            <NotificationsActiveOutlinedIcon sx={{ color: "#292886" }} />
            <Typography sx={{ ...ProfileSectionText, fontWeight: 300 }}>
              Notifications
            </Typography>
          </Box>
          {/* <ArrowForwardIosRoundedIcon /> */}
        </Box>
        {/* </Link> */}
      </div>
      <Box sx={{ padding: "0px 10px" }}>
        {/* <Link> */}
        <Button
          sx={ButtonCart}
          onClick={(e) => {
            e.preventDefault();
            handleOpen();
          }}
        >
          <LogoutRoundedIcon sx={buttonIcon} /> Log Out
        </Button>
        {/* </Link> */}
      </Box>

      <Modal
        open={open1}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: "50vw",
            bgcolor: "background.paper",
            borderRadius: "12px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h6">
            Are you Sure, You want to log out?
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              className="create-new-post-button"
              style={{
                margin: "10px 20px",
                backgroundColor: "#CD7F32",
              }}
              onClick={logoutHandler}
            >
              Logout
            </button>
            <button
              className="create-new-post-button"
              style={{
                margin: "10px 20px",
                backgroundColor: "#CD7F32",
              }}
              onClick={handleClose}
            >
              Cancel
            </button>
          </div>
        </Box>
      </Modal>
    </Box>
  );
};

export default Profile;
