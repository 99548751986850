// import { BrowserRouter, Route, Routes } from "react-router-dom";
// unod this and your back
import { createContext, useEffect, useState } from "react";
import "./App.css";
import AdminNavbar from "./Components/Admin/AdminNavbar";
// import server from "./Components/server";
import { Box } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DashboardState from "./Components/Admin/Context/DashboardState";

//client imports
import Navbar from "./Components/User/Navbar/Navbar";
// import { Route, Routes } from "react-router-dom";
import AddAddress from "./Components/User/AddAddress/AddAddress";
import Cart from "./Components/User/Cart/Cart";
import Category from "./Components/User/Category/Category";
import CategoryProducts from "./Components/User/CategoryProducts/CategoryProducts";
import Home from "./Components/User/Home/Home";
import MyAddress from "./Components/User/MyAddress/MyAddress";
import Notification from "./Components/User/Notification/Notification";
import OrderAcceptedPage from "./Components/User/OrderAccepted/OrderAcceptedPage";
import Orders from "./Components/User/Orders/Orders";
import PlaceOrder from "./Components/User/PlaceOrder/PlaceOrder";
import ProductDetails from "./Components/User/ProductDetails/ProductDetails";
import Profile from "./Components/User/Profile/Profile";
import ReturnOrder from "./Components/User/ReturnOrder/ReturnOrder";
import ReviewPage from "./Components/User/ReviewPage/ReviewPage";
import Search from "./Components/User/Search/Search";
import ShoppingCart from "./Components/User/ShoppingCart/ShoppingCart";
import SubCategory from "./Components/User/SubCategory/SubCateogry";
import TrackOrder from "./Components/User/TrackOrder/TrackOrder";
import Wishlist from "./Components/User/Wishlist/Wishlist";
import WriteReviewPage from "./Components/User/WriteReviewPage/WriteReviewPage";
// import Verification from "./Verification";
import EditAddress from "./Components/User/EditAddress/EditAddress";
import { NavBarContext } from "./Components/User/Navbar/Navbar";
import ViewOrder from "./Components/User/ViewOrderDetails/ViewOrder";
import ViewReturnOrder from "./Components/User/ViewReturnOrder/ViewReturnOrder";

import PrivateRoute from "./Components/PrivateRoute/PrivateRoute";
import InvalidPincode from "./Components/User/InvalidPincode/InvalidPincode";
// import EditProduct from "./Components/Admin/Inventory/Layout/EditProductForm";
import About from "./Components/User/About/About";

import axios from "axios";
// import PageNotFound from "../src/Components/PageNotFound/PageNotFound";
import Login from "./Components/Admin/Login";
import PrivateLogin from "./Components/PrivateRoute/PrivateLogin";
import server from "./Components/server";
import PrintOrder from "./Components/User/PlaceOrder/PrintOrder";
import Scanner from "./Components/User/Scanner/Scanner";
import UserLogin from "./Components/User/UserLogin";
import { logEvent, analytics, FirebaseEvent } from "./firebase";

export const UserContext = createContext();

function App() {
  const [user, setUser] = useState(false);
  const [client, setClient] = useState(false);
  const [profileopen, setProfileopen] = useState(false);
  const [items, setItems] = useState(0); // for client side
  const [note, setNote] = useState(""); // for client side
  const [wishList, setWishList] = useState([]);

  useEffect(() => {
    logEvent(analytics, FirebaseEvent.APP_LAUNCH);
    if (localStorage.getItem("adminuser")) {
      setUser(true);
    } else if (localStorage.getItem("user")) {
      setClient(true);
    } else setClient(false);
  }, []);

  function isMobileBrowser() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
      userAgent,
    );
  }

  const [s3, setS3] = useState();

  const fetchWishList = async () => {
    // console.log(localStorage.getItem('user'))
    const result = await axios.get(
      `${server}/user/wishlist/status/${localStorage.getItem("user")}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    setWishList(result.data[0].productList);
  };

  const requestOptions = {
    headers: {
      method: "GET",
      Authorization: `Bearer ${localStorage.getItem("access")}`,
    },
  };
  useEffect(() => {
    fetchWishList();
  }, []);

  // const UserProtectedRoute = (children) => {
  //   console.log("working");
  //   return !localStorage.getItem("user") ||
  //     !localStorage.getItem("adminuser") ? (
  //     <Navigate to="/" />
  //   ) : null;
  // };

  return (
    <>
      {localStorage.getItem("adminuser") ? (
        <div className="App">
          <UserContext.Provider value={{ user, setUser }}>
            <DashboardState>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
              <AdminNavbar setOpen={setProfileopen} />
              {/* <Profileupdate open={profileopen} setOpen={setProfileopen} /> */}
              <Box sx={{ display: "flex" }} className="test">
                {/* <DrawerHeader /> */}

                {/* <Route path="/viewproduct" element={<ViewProduct />} /> */}
                {/* <Route path="/viewcustomer" element={<CustomerOrder />} /> */}
                {/* <Route path="/content" element={<Content />} /> */}
                {/* <Route path="/pincodelist" element={<PinCodeList />} /> */}
                {/* <Route path="/viewusernetwork" element={<ViewUserNetwork />} /> */}
              </Box>
            </DashboardState>
          </UserContext.Provider>
        </div>
      ) : (
        client &&
        isMobileBrowser && (
          <Box className="App">
            <NavBarContext.Provider
              value={{
                items,
                setItems,
                client,
                setClient,
                note,
                setNote,
                s3,
                wishList,
                fetchWishList,
              }}
            >
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
              {<Navbar />}
              <Routes>
                <Route path="/login" element={<UserLogin />} />
                {/* <Route path="/login" element={
            <PrivateLogin>
            <UserLogin />
            </PrivateLogin>
            } /> */}

                {/* <Route path="/verification" element={<Verification />} /> */}

                <Route
                  path="/home"
                  element={
                    <PrivateRoute>
                      <Home />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/category"
                  element={
                    <PrivateRoute>
                      <Category />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/subcategory/:catid"
                  element={
                    <PrivateRoute>
                      <SubCategory />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/cart"
                  element={
                    <PrivateRoute>
                      <Cart />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/categoryproducts/:name/:catid/:categoryId"
                  element={
                    <PrivateRoute>
                      <CategoryProducts />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/productdetail/:prodid"
                  element={
                    <PrivateRoute>
                      <ProductDetails />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/review/:prodid"
                  element={
                    <PrivateRoute>
                      <ReviewPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/writereview/:prodid"
                  element={
                    <PrivateRoute>
                      <WriteReviewPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/placeorder"
                  element={
                    <PrivateRoute>
                      <PlaceOrder />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/orderplaced/:status/:orderId"
                  element={
                    <PrivateRoute>
                      <OrderAcceptedPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/trackorder/:orderId"
                  element={
                    <PrivateRoute>
                      <TrackOrder />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/cartempty"
                  element={
                    <PrivateRoute>
                      <ShoppingCart />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <PrivateRoute>
                      <Profile />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/wishlist"
                  element={
                    <PrivateRoute>
                      <Wishlist />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/about"
                  element={
                    <PrivateRoute>
                      <About />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/myaddress"
                  element={
                    <PrivateRoute>
                      <MyAddress />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/addaddress"
                  element={
                    <PrivateRoute>
                      <AddAddress />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/editaddress/:addid"
                  element={
                    <PrivateRoute>
                      <EditAddress />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/notification"
                  element={
                    <PrivateRoute>
                      <Notification />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/client/orders"
                  element={
                    <PrivateRoute>
                      <Orders />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/returnorder/:orderId"
                  element={
                    <PrivateRoute>
                      <ReturnOrder />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/returnorder/view/:returnId"
                  element={
                    <PrivateRoute>
                      <ViewReturnOrder />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/vieworder/:orderId"
                  element={
                    <PrivateRoute>
                      <ViewOrder />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/cancel/:orderId"
                  element={
                    <PrivateRoute>
                      <ViewOrder pageType={"cancelOrder"} />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/search"
                  element={
                    <PrivateRoute>
                      <Search />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/invalidpincode"
                  element={
                    <PrivateRoute>
                      <InvalidPincode />
                    </PrivateRoute>
                  }
                />
                {/* <Route
                  path="/*"
                  element={
                    <PrivateRoute>
                      <Home />
                    </PrivateRoute>
                  }
                /> */}
                <Route
                  path="/scanner"
                  element={
                    <PrivateRoute>
                      <Scanner />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/printorder/:orderID"
                  element={
                    <PrivateRoute>
                      <PrintOrder />
                    </PrivateRoute>
                  }
                />

                {/* <Route path="*" element={<PageNotFound />} /> */}
              </Routes>
            </NavBarContext.Provider>
          </Box>
        )
      )}

      <>
        <UserContext.Provider value={{ user, setUser }}>
          <DashboardState>
            <div className="App">
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
              <Box sx={{ display: "flex" }}>
                <Routes>
                  <Route
                    path="/admin/login"
                    element={
                      <PrivateLogin>
                        <Login />
                      </PrivateLogin>
                    }
                  />
                </Routes>
              </Box>
            </div>
          </DashboardState>
        </UserContext.Provider>
        <Box className="App">
          <NavBarContext.Provider
            value={{ items, setItems, client, setClient }}
          >
            <Routes>
              <Route
                path="/"
                element={
                  <PrivateLogin>
                    <UserLogin />
                  </PrivateLogin>
                }
              />
              {/* <Route path="*" element={<PageNotFound />} /> */}
            </Routes>
          </NavBarContext.Provider>
        </Box>
      </>

      <>{/* <UserProtectedRoute /> */}</>
    </>
  );
}

export default App;
