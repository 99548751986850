import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, Button, IconButton, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import server from "../../server";
import AppReview from "../Review/AppReview";
import CartProductCard from "./Layout/CartProductCard";

import ShoppingCart from "../ShoppingCart/ShoppingCart";

const predefinedSubcategoryList = [
  "66bddf6d9007e171a9d85fe3",
  "66bde1379007e171a9d860ef",
  "66bde1379007e171a9d860f2",
  "66bde1379007e171a9d860f5",
  "66bde1379007e171a9d86113",
  "66bde1379007e171a9d86116",
  "66bde1379007e171a9d8611f",
  "66bde1379007e171a9d86128",
];

const header = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  backgroundColor: "#fff",
  borderBottom: "1px solid #EAEAEA",
};

const arrowStyle = {
  position: "absolute",
  left: "20px",
};

const CategoryTitle = {
  fontWeight: "600",
  fontFamily: "Quicksand",
};

const ButtonCart = {
  backgroundColor: "#CD7F32",
  color: "#fff",
  textTransform: "none",
  padding: "10px",
  fontSize: "16px",
  fontWeight: "500",
  width: "90%",
  fontFamily: "Poppins",
  "&.MuiButtonBase-root:hover": {
    background: "#D98B3B",
  },
  mx: "5%",
};

const Cart = () => {
  const navigate = useNavigate();
  const [productData, setProductData] = useState();

  //states
  const [cart, setCart] = useState([]);
  const [cartprice, setCartPrice] = useState(localStorage.getItem("cartprice"));
  const [netMrp, setNetMrp] = useState();
  const [yourSaving, setYourSaving] = useState();

  const getcartinfo = async () => {
    setCart(JSON.parse(localStorage.getItem("cart")));
  };

  const productDetails = async (productId) => {
    try {
      const result = await axios.get(`${server}/products/${productId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      setProductData(result.data.product);
      return result?.data?.product;
    } catch (error) {
      console.log(error);
    }
  };

  const handleFlow = async () => {
    const addressresult = await axios.get(
      `${server}/address/user/${localStorage.getItem("user")}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );

    // console.log(addressresult.data)

    //check for validity of order minimum rs 2000
    if (cartprice < 2000) {
      toast.warning("Cannot place orders below ₹2000", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    const cart = JSON.parse(localStorage.getItem("cart"));
    await Promise.all(
      cart.map(async (item) => {
        if (Array.isArray(item) && item.length === 3) {
          const [id, variant, quantity] = item;
          const data = await productDetails(id);
          // if (data?.subCategoryId?.name?.includes("Loose"))
          if (predefinedSubcategoryList.includes(data?.subCategoryId?._id)) {
            if (variant >= 100 && variant * quantity <= 250) {
              toast.warning("Product Need to be More than 250 g");
            }
            // else if (variant < 100 && variant * quantity !== 30) {
            //   toast.warning("Product Need to be Less than 30 Kg");
            // }
          } else if (
            data?.subCategoryId?.name?.includes("Loose whole spices")
          ) {
            if (variant >= 100 && variant * quantity <= 50) {
              toast.warning("Product Need to be More than 50 g");
            } else if (variant < 100 && variant * quantity !== 10) {
              toast.warning("Product Need to be Less than 10 Kg");
            }
          }
        }
      }),
    );

    if (addressresult.data.length === 0) {
      //go to add address page with status true
      navigate("/addaddress", { state: { status: "true" } });
    } else {
      navigate("/myaddress", { state: { status: "true" } });
    }
  };

  const getProductById = async (productId) => {
    console.log("id of product is ", productId);
    const product = await axios.get(`${server}/product/admin/${productId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return product?.data[0];
  };

  const calculateNetMRP = async () => {
    let netMRP = 0;
    const cart = JSON.parse(localStorage.getItem("cart"));
    const productPromises = cart.map(async (item) => {
      if (item?.length == 2) {
        const [productId, quantity] = item;
        const product = await getProductById(productId);

        if (product) {
          return product.mrpPrice * quantity;
        }
        return 0;
      } else {
        const [productId, variant, quantity] = item;
        const product = await getProductById(productId);

        console.log({ mrpPrice: product.mrpPrice, quantity });

        // if (product?.subCategoryId?.name?.includes("Loose")) {
        return variant <= 100
          ? product.mrpPrice * variant * quantity
          : product.mrpPrice * (variant / 1000) * quantity;
        // }
      }
    });
    const productMRPs = await Promise.all(productPromises);
    netMRP = productMRPs.reduce((total, mrp) => total + mrp, 0);
    return netMRP;
  };

  useEffect(() => {
    if (!localStorage.getItem("user")) navigate("/");
    else getcartinfo();
  }, []);

  useEffect(() => {
    const fetchAndCalculateMRP = async () => {
      const netMrpValue = await calculateNetMRP();
      setYourSaving(netMrpValue - cartprice);
      setNetMrp(netMrpValue);
    };

    fetchAndCalculateMRP();
  }, [cartprice]);

  console.log("netpmrp ", netMrp);
  console.log("cartprice ", cartprice);
  console.log("saving", netMrp - cartprice);
  return (
    <>
      <AppReview />
      <ToastContainer />
      {JSON.parse(localStorage.getItem("cartprice")) === 0 ? (
        <ShoppingCart />
      ) : (
        <Box sx={{ marginBottom: "100px" }}>
          <Box sx={header}>
            <Box sx={arrowStyle}>
              <Link to="/">
                <IconButton>
                  <ArrowBackRoundedIcon />
                </IconButton>
              </Link>
            </Box>
            <Typography variant="h6" sx={CategoryTitle}>
              Cart
            </Typography>
          </Box>

          {cart &&
            cart.map((prod, i) => {
              return (prod.length === 3 && parseInt(prod[2]) > 0) ||
                (prod.length == 2 && parseInt(prod[1]) > 0) ? (
                <CartProductCard
                  proddetail={prod}
                  setCartPrice={setCartPrice}
                  key={i}
                />
              ) : (
                <></>
              );
            })}
          <Box
            sx={{
              // padding: "0px 10px",
              position: "fixed",
              bottom: 0,
              // left: "50vw",
              // backgroundColor: "#fff",
              // borderRadius: "7px",
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              // zIndex: 10,
              width: "100%",
              // padding: "10px",
              marginBottom: "100px",
            }}
          >
            {/* <Link to="/placeorder"> */}
            <Button
              sx={ButtonCart}
              onClick={(e) => {
                handleFlow();
              }}
            >
              Checkout
            </Button>
            {/* </Link> */}
          </Box>
          {/* {cartprice && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "10px 20px",
                marginBottom: "150px",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700px",
                  fontFamily: "Poppins",
                  fontSize: "15px",
                }}
              >
                Total{" "}
              </Typography>
              <Typography
                sx={{
                  fontWeight: "700px",
                  fontFamily: "Poppins",
                  fontSize: "15px",
                }}
              >
                ₹{cartprice}
              </Typography>
            </Box>
          )} */}

          {cartprice && (
            <Box
              sx={{
                padding: "10px 20px",
                marginBottom: "150px",
              }}
            >
              {/* Total Amount */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: "10px", // Adding some space between total and savings
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "700px",
                    fontFamily: "Poppins",
                    fontSize: "15px",
                  }}
                >
                  Total
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "700px",
                    fontFamily: "Poppins",
                    fontSize: "15px",
                  }}
                >
                  ₹{cartprice}
                </Typography>
              </Box>

              {/* Your Saving */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "700px",
                    fontFamily: "Poppins",
                    fontSize: "15px",
                  }}
                >
                  Your Saving
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "700px",
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    color: "green",
                  }}
                >
                  ₹ {yourSaving}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default Cart;
