import CloseIcon from "@mui/icons-material/Close";
import RateReviewIcon from "@mui/icons-material/RateReview";
import StarIcon from "@mui/icons-material/Star";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Rating,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const AppReview = ({ open, handleClose, reviewLink }) => {
  const navigate = useNavigate();
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Leave a Review</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            textAlign: "center",
            padding: "20px",
          }}
        >
          <RateReviewIcon sx={{ fontSize: 50, color: "#fbc02d", mb: 2 }} />
          <Typography variant="body1" gutterBottom>
            We hope you loved your purchase!
          </Typography>
          <Typography variant="body2" color="textSecondary" mb={2}>
            Please take a moment to leave a review and help us improve.
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 2,
            }}
          >
            <Rating
              name="read-only"
              value={5}
              readOnly
              precision={0.5}
              icon={<StarIcon fontSize="inherit" />}
              emptyIcon={<StarIcon fontSize="inherit" />}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            size="large"
            href={reviewLink}
            target="_blank"
            sx={{
              borderRadius: "25px",
              padding: "10px 20px",
              textTransform: "none",
            }}
            endIcon={<StarIcon />}
            onClick={handleClose}
          >
            Write a Review
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AppReview;
