/* global grecaptcha */
// import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../App.css";
import logo from "../../assets/AgarwalAppLogo.png";
// import LoginBG from "../../assets/login-mobile.png";
import app, { analytics, FirebaseEvent, logEvent } from "../../firebase";
import server from "../server";
import InvalidPincode from "./InvalidPincode/InvalidPincode";
import { NavBarContext } from "./Navbar/Navbar";
const auth = getAuth();

const ___ = app;

const InputPhoneStyle = {
  sx: {
    fieldset: {
      borderRadius: 0,
      borderWidth: 0,
    },
    "&:focus-within fieldset, &:focus-visible fieldset": {
      border: "none !important",
    },
    input: {
      "&::placeholder": {
        color: "#868889 !important",
        fontSize: "16px",
        padding: "0px 0px 0px 10px",
        mixBlendMode: "normal",
        opacity: "0.87",
        fontWeight: "400",
        fontFamily: "poppins",
        fontSize: "16px",
      },
    },
  },
  startAdornment: (
    <InputAdornment position="start">
      <LocalPhoneOutlinedIcon sx={{ color: "#37474F" }} />
      <Typography>+91</Typography>
    </InputAdornment>
  ),
};

const MobileNo = {
  fontSize: "14px",
  fontWeight: "400",
  fontFamily: "poppins",
  color: "#37474F",
  margin: "5px 0px 20px 0px",
};

const InputPincodeStyle = {
  sx: {
    maxLength: 1,
    fieldset: {
      borderRadius: 0,
      borderWidth: 0,
    },
    "&:focus-within fieldset, &:focus-visible fieldset": {
      border: "none !important",
    },
    input: {
      "&::placeholder": {
        color: "#868889 !important",
        fontSize: "16px",
        padding: "0px 0px 0px 10px",
        mixBlendMode: "normal",
        opacity: "0.87",
        fontWeight: "600",
        fontFamily: "quicksand",
      },
    },
  },
};

const EnterMobileText = {
  fontSize: "20px",
  fontWeight: "500",
  fontFamily: "Poppins",
  color: "#37474F",
};

const verifyText = {
  fontSize: "14px",
  fontWeight: "400",
  fontFamily: "Poppins",
  color: "#818B90",
  margin: "5px 0px 5px 0px",
};

const ButtonCart = {
  backgroundColor: "#CD7F32",
  color: "#fff",
  marginBottom: "20px",
  textTransform: "none",
  padding: "10px 10px",
  fontSize: "18px",
  fontWeight: "500",
  fontFamily: "Poppins",
  width: "100%",
  "&.MuiButtonBase-root:hover": {
    background: "#D98B3B",
  },
};

function removeCountryCode(number) {
  return number.replace(/\s/g, "").replace(/^(\+91)\s*/, "");
}

const UserLogin = () => {
  const navigate = useNavigate();
  const navcontext = useContext(NavBarContext);

  const { setClient } = navcontext;

  const pinFields = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const [loading, setLoading] = useState(false);

  const [mobile, setMobile] = useState("");
  const [pincode, setPincode] = useState("");
  const [show, setShow] = useState(false);
  const [showModal, setModal] = useState(false);
  const [isRegister, setRegister] = useState(false);

  const [numone, setNumOne] = useState("");
  const [numtwo, setNumTwo] = useState("");
  const [numthree, setNumThree] = useState("");
  const [numfour, setNumFour] = useState("");
  const [numfive, setNumFive] = useState("");
  const [numsix, setNumSix] = useState("");
  const [user, setUser] = useState();
  const arr = Array(6).fill(0);

  async function setUpRecaptcha(mobile) {
    setLoading(true);
    let recaptchaVerifier;
    try {
      recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
        },
        auth,
      );
    } catch (error) {
      console.log(error);
    }
    recaptchaVerifier.render();
    let result;
    try {
      result = await signInWithPhoneNumber(auth, mobile, recaptchaVerifier)
        .then((result) => {
          window.confirmobj = result;
          setShow(true);
          logEvent(analytics, FirebaseEvent.USER_LOGIN, { type: "Admin" });
        })
        .catch((error) => {
          console.log(error);
          setShow(false);
          alert(error);
        });
    } catch (error) {
      console.log(error);
    }

    // recaptchaVerifier.clear()
    setLoading(false);

    return result;
  }

  const SubmitMobile = async () => {
    if (mobile === "" || mobile.length != 10) {
      return toast.warning("Enter a Valid Number", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    if (isRegister) {
      //check pincode against all valid pincodes
      const validpc = await axios.get(
        `${server}/auth/pincodevalidate/${pincode}`,
      );
      // console.log(validpc)
      if (validpc.data === null) {
        setModal(true);
        return;
        // navigate('/invalidpincode')
      } else {
        //pc is valid and number is old one or could be changed
        //even if no. changes doesnt matter so just continue
        //call otp etc
        try {
          // setLoading(true)

          const ConfirmationResultImpl = await setUpRecaptcha("+91" + mobile);
          // setLoading(false)
        } catch (err) {
          console.log(err);
          // setLoading(false)
        }
      }
    }

    //check in db if already exist
    if (!isRegister) {
      const data = await axios.get(
        `${server}/auth/usersbymobile/search/${"+91" + mobile}`,
      );
      if (data.data.message !== "user already registered") {
        setRegister(true);
        return toast.warning(
          "Looks Like you are a new User. Please enter your Pincode",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          },
        );
      } else {
        //call otp etc
        try {
          const ConfirmationResultImpl = await setUpRecaptcha("+91" + mobile);
        } catch (err) {
          console.log(err);
        }
      }
    }
    // else {
    //   try {

    //     const ConfirmationResultImpl = await setUpRecaptcha(mobile);

    //   } catch (err) {
    //     console.log(err);
    //   }

    // }
  };

  const onSignInSubmit = (e) => {
    SubmitMobile();
  };

  //functions to communicate with backend

  const verifyOtp = async (e) => {
    e.preventDefault();
    const otp = numone + numtwo + numthree + numfour + numfive + numsix;
    // console.log(otp)
    if (otp === "" || otp === null) return;
    try {
      // console.log("name print")
      await window.confirmobj.confirm(otp);

      //backend api call
      try {
        // localStorage.getItem('_grecaptcha')

        const data = await axios.post(`${server}/auth/users/register`, {
          mobile: "+91" + mobile,
          pincode,
        });
        if (!data.data.message) {
          //successfull
          // console.log(data + " registered new");
          setUser(data.data.user);
          localStorage.setItem("user", data.data.user._id);
          localStorage.setItem("accessToken", data.data.accessToken);
          setClient(true);
          //create a cart for this user later here)
        } else {
          //show error message received in response on the UI
          // console.log("old usr");
          setUser(data.data.user);
          localStorage.setItem("user", data.data.user._id);
          localStorage.setItem("accessToken", data.data.accessToken);
          setClient(true);
          // console.log("user reg already" + localStorage.getItem('user'))
          //currently no error but u receive the data and accesstoken
        }
      } catch (error) {
        console.log(error);
      }

      toast.success("OTP verified successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      if (!localStorage.getItem("cart")) {
        localStorage.setItem("cart", JSON.stringify([]));
        localStorage.setItem("cartprice", 0);
      }

      navigate("/home");
    } catch (err) {
      toast.error("OTP Incorrect", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(err);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("user")) navigate("/home");
    if (localStorage.getItem("adminuser")) navigate("/dashboard");
  }, []);

  useEffect(() => {}, [show]);

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: "1201" }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        // style={ { width:'40%'}}
      />
      {showModal && <InvalidPincode modalstate={setModal} />}
      <Box sx={{ height: "100%", marginBottom: "100px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            maxHeight: "200rem",
            alignItems: "end",
            marginTop: "20px",
            // overflow: "auto",
            marginBottom: "20px",
          }}
        >
          <img
            src={logo}
            alt="Mobile BG"
            className="login-bg"
            style={{ maxHeight: "20rem" }}
          />
          <h1
            style={{
              // position: "absolute",
              // top: "40px",
              // left: "10px",
              fontSize: "20px",
              fontFamily: "'Playfair Display', serif",
              fontSize: "20px",
              color: "#333",
              // margin: 0,
              // textAlign: "center",
            }}
          >
            Since 1978
          </h1>
        </Box>
        <div id="recaptcha-container" />
        {show ? (
          <>
            <Box sx={{ padding: "0px 20px", margin: "20px 0px" }}>
              <Typography sx={EnterMobileText}>
                Enter Verification Code
              </Typography>
              <Typography sx={verifyText}>We have sent SMS to:</Typography>
              <Typography sx={MobileNo}>+91XXXXXXXXXX</Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <TextField
                  inputRef={pinFields[0]}
                  sx={{
                    backgroundColor: "#F0F1F2",
                    borderRadius: "5px",
                    width: "18%",
                    margin: "4px 10px 4px 0px",
                  }}
                  inputProps={{
                    maxLength: 1,
                  }}
                  InputProps={InputPincodeStyle}
                  onChange={(e) => {
                    setNumOne(e.target.value);
                    if (e.target.value.length === 1)
                      pinFields[1].current.focus();
                  }}
                />
                <TextField
                  inputRef={pinFields[1]}
                  sx={{
                    backgroundColor: "#F0F1F2",
                    borderRadius: "5px",
                    width: "18%",
                    margin: "4px 10px 4px 0px",
                  }}
                  inputProps={{
                    maxLength: 1,
                  }}
                  InputProps={InputPincodeStyle}
                  onChange={(e) => {
                    setNumTwo(e.target.value);
                    if (e.target.value.length === 1)
                      pinFields[2].current.focus();
                  }}
                />

                <TextField
                  inputRef={pinFields[2]}
                  sx={{
                    backgroundColor: "#F0F1F2",
                    borderRadius: "5px",
                    width: "18%",
                    margin: "4px 10px 4px 0px",
                  }}
                  inputProps={{
                    maxLength: 1,
                  }}
                  InputProps={InputPincodeStyle}
                  onChange={(e) => {
                    setNumThree(e.target.value);
                    if (e.target.value.length === 1)
                      pinFields[3].current.focus();
                  }}
                />

                <TextField
                  inputRef={pinFields[3]}
                  sx={{
                    backgroundColor: "#F0F1F2",
                    borderRadius: "5px",
                    width: "18%",
                    margin: "4px 10px 4px 0px",
                  }}
                  inputProps={{
                    maxLength: 1,
                  }}
                  InputProps={InputPincodeStyle}
                  onChange={(e) => {
                    setNumFour(e.target.value);
                    if (e.target.value.length === 1)
                      pinFields[4].current.focus();
                  }}
                />

                <TextField
                  inputRef={pinFields[4]}
                  sx={{
                    backgroundColor: "#F0F1F2",
                    borderRadius: "5px",
                    width: "18%",
                    margin: "4px 10px 4px 0px",
                  }}
                  inputProps={{
                    maxLength: 1,
                  }}
                  InputProps={InputPincodeStyle}
                  onChange={(e) => {
                    setNumFive(e.target.value);
                    if (e.target.value.length === 1)
                      pinFields[5].current.focus();
                  }}
                />
                <TextField
                  inputRef={pinFields[5]}
                  sx={{
                    backgroundColor: "#F0F1F2",
                    borderRadius: "5px",
                    width: "18%",
                    margin: "4px 0px 4px 0px",
                  }}
                  inputProps={{
                    maxLength: 1,
                  }}
                  InputProps={InputPincodeStyle}
                  onChange={(e) => setNumSix(e.target.value)}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "5px",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "#F37A20",
                    }}
                  >
                    {" "}
                    Resend OTP
                  </Typography>
                </Box>
                <Box>
                  <Button
                    sx={{
                      textTransform: "none",
                      textDecoration: "none",
                      color: "#37474F",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.reload();
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "poppins",
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "#37474F",
                      }}
                    >
                      {" "}
                      Change Number
                    </Typography>
                  </Button>
                </Box>
              </Box>

              <Box sx={{ padding: "0px 4px" }}>
                {/* <Link> */}
                <Button sx={ButtonCart} onClick={(e) => verifyOtp(e)}>
                  Next
                  <ArrowForwardRoundedIcon
                    sx={{
                      position: "absolute",
                      right: "20px",
                    }}
                  />
                </Button>
                {/* </Link> */}
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box sx={{ padding: "0px 20px", margin: "20px 0px" }}>
              <Typography sx={EnterMobileText}>
                Enter your mobile number
              </Typography>
              <Typography sx={verifyText}>
                We need to verify you. We will send you a one time verification
                code.{" "}
              </Typography>

              <TextField
                placeholder="Phone Number"
                onChange={(e) => {
                  setMobile(removeCountryCode(e.target.value));
                }}
                sx={{
                  backgroundColor: "#F0F1F2",
                  borderRadius: "5px",
                  width: "100%",
                  margin: "4px 0px",
                }}
                InputProps={InputPhoneStyle}
                InputLabelProps={{
                  sx: {
                    color: "#868889",
                    textTransform: "capitalize",
                  },
                }}
              />
              {isRegister && (
                <TextField
                  placeholder="Enter Pincode"
                  onChange={(e) => {
                    setPincode(e.target.value);
                  }}
                  sx={{
                    backgroundColor: "#F0F1F2",
                    borderRadius: "5px",
                    width: "100%",
                    margin: "4px 0px",
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: "16px",
                  }}
                  InputProps={InputPincodeStyle}
                  InputLabelProps={{
                    sx: {
                      color: "#868889",
                      textTransform: "capitalize",
                    },
                  }}
                />
              )}
            </Box>

            <Box
              sx={{
                padding: "0px 20px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Button
                sx={ButtonCart}
                onClick={(e) => {
                  e.preventDefault();
                  onSignInSubmit(e);
                }}
              >
                Next
                <ArrowForwardRoundedIcon
                  sx={{ position: "absolute", right: "20px" }}
                />
              </Button>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default UserLogin;
